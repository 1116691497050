.sectionHeader {
  > .header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 25px;
    @include media-breakpoint-up(sm) {
      margin-bottom: 45px;
    }
    .title-en {
      color: $primary;
      font-family: $font-family-en;
      font-weight: normal;
      font-size: 35px;
      margin: 0;
      @include media-breakpoint-up(sm) {
        font-size: 42px;
      }
    }
    .title-ja {
      font-size: 13px;
      margin: 0;
      @include media-breakpoint-up(sm) {
        font-size: 15px;
      }
    }
  }
}
