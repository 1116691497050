.historyArchive {
  display: flex;
  flex-direction: column;
  gap: 10px;
  .item {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    background-color: $bg-beige;
    border-radius: $border-radius;
    color: $primary;
    text-decoration: none;
    .cont {
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding: 15px 30px;
      @include media-breakpoint-up(sm) {
        padding: 20px 45px;
      }
      .title {
        margin: 0;
        font-size: 18px;
        font-weight: bold;
        @include media-breakpoint-up(sm) {
          font-size: 24px;
        }
      }
      .data {
        color: $body-color;
      }
    }
    .icon {
      font-size: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 15px 30px;
      @include media-breakpoint-up(sm) {
        padding: 20px 45px;
      }
    }
  }
}
