.archiveItem {
  border-bottom: 1px solid $primary;
  padding: 60px 0;
  @include media-breakpoint-up(sm) {
    padding: 75px 0;
  }
  &:last-child {
    border-bottom: 0;
  }
  > .header {
    margin-bottom: 45px;
    @include media-breakpoint-up(sm) {
      margin-bottom: 60px;
    }
    .title {
      font-size: 18px;
      margin: 0;
      line-height: 1.25;
      color: $primary;
      text-align: center;
      font-weight: bold;
      @include media-breakpoint-up(sm) {
        font-size: 32px;
      }
    }
  }
  > .body {
    font-size: 15px;
    @include media-breakpoint-up(sm) {
      font-size: 21px;
    }
    p {
      margin-bottom: 1em;
    }
    h2 {
      background-color: $bg-blue;
      padding: 15px;
      font-size: 15px;
      font-weight: bold;
      margin-bottom: 1em;
      @include media-breakpoint-up(sm) {
        font-size: 21px;
      }
    }
    > ul {
      margin-bottom: 1em;
      padding-left: 1em;
      list-style-type: none;
      > li {
        text-indent: -1em;
        &::before {
          content: "●";
          color: $primary;
        }
        > ul {
          padding-left: 1em;
          list-style-type: disc;
          li {
            text-indent: 0;
          }
        }
      }
    }
  }
}
