.heroBG2 {
  position: relative;
  // margin-top: calc(#{$headerHeight} / -1);
  @include media-breakpoint-up(lg) {
    margin-top: calc(#{$headerHeightSM} / -1);
  }
  > .bg {
    overflow: hidden;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    .wave {
      width: 100%;
      height: auto;
      position: absolute;
      z-index: 2;
      bottom: calc(100% - 162vw);
      left: 0;
      @include media-breakpoint-up(sm) {
        bottom: calc(100% - 144vw);
      }
      @include media-breakpoint-up(lg) {
        bottom: calc(100% - 100vh);
      }
      img {
        width: 100%;
      }
    }
    .bgColor1 {
      background-color: $bg-beige;
      width: 100%;
      height: 162vw;
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      @include media-breakpoint-up(sm) {
        height: 144vw;
      }
      @include media-breakpoint-up(lg) {
        height: 100vh;
      }
    }
    .bgColor2 {
      background-color: $bg-blue;
      width: 100%;
      height: calc(100% - 162vw);
      position: absolute;
      z-index: 1;
      top: 162vw;
      left: 0;
      @include media-breakpoint-up(sm) {
        height: calc(100% - 144vw);
        top: 144vw;
      }
      @include media-breakpoint-up(lg) {
        height: calc(100% - 100vh);
        top: 100vh;
      }
    }
    .heroBG01 {
      position: absolute;
      z-index: 3;
      top: 0;
      left: 0;
      width: 100vw;
      height: 162vw;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      $imgWidth: calc(200 / 390 * 100vw);
      img {
        width: $imgWidth;
        transform: translateX(-40%) translateY(-40%);
      }
      @include media-breakpoint-up(sm) {
        $imgWidth: calc(360 / 820 * 100vw);
        height: 144vw;
        img {
          width: $imgWidth;
          transform: translateX(-10%) translateY(-20%);
        }
      }
      @include media-breakpoint-up(lg) {
        $imgWidth: calc(279 / 1366 * 100vw);
        height: 100vh;
        img {
          width: $imgWidth;
        }
      }
    }
    .heroBG02 {
      position: absolute;
      z-index: 3;
      top: 0;
      left: 0;
      width: 100vw;
      height: 162vw;
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
      $imgWidth: calc(300 / 390 * 100vw);
      img {
        width: $imgWidth;
        transform: translateX(20%) translateY(-30%);
      }
      @include media-breakpoint-up(sm) {
        $imgWidth: calc(600 / 820 * 100vw);
        height: 144vw;
        img {
          width: $imgWidth;
        }
      }
      @include media-breakpoint-up(lg) {
        $imgWidth: calc(500 / 1366 * 100vw);
        height: 100vh;
        img {
          width: $imgWidth;
        }
      }
    }
    .heroBG03 {
      position: absolute;
      z-index: 3;
      top: 0;
      left: 0;
      width: 100vw;
      height: 162vw;
      display: flex;
      align-items: flex-end;
      justify-content: flex-start;
      $imgWidth: calc(360 / 390 * 100vw);
      img {
        width: $imgWidth;
        transform: translateX(-20%) translateY(90%);
      }
      @include media-breakpoint-up(sm) {
        $imgWidth: calc(600 / 820 * 100vw); //c
        height: 144vw;
        img {
          width: $imgWidth;
          transform: translateX(-15%) translateY(30%);
        }
      }
      @include media-breakpoint-up(lg) {
        $imgWidth: calc(484 / 1366 * 100vw);
        height: 100vh;
        img {
          width: $imgWidth;
        }
      }
    }
    .heroBG04 {
      position: absolute;
      z-index: 3;
      top: 0;
      left: 0;
      width: 100vw;
      height: 162vw;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      $imgWidth: calc(188 / 390 * 100vw);
      img {
        width: $imgWidth;
        transform: translateX(10%) translateY(15%);
      }
      @include media-breakpoint-up(sm) {
        $imgWidth: calc(300 / 820 * 100vw);
        height: 144vw; //
        img {
          width: $imgWidth;
          transform: translateX(5%) translateY(-6%);
        }
      }
      @include media-breakpoint-up(lg) {
        $imgWidth: calc(270 / 1366 * 100vw);
        height: 100vh;
        img {
          width: $imgWidth;
          transform: translateX(-20%) translateY(-6%);
        }
      }
    }
    .heroBG05 {
      position: absolute;
      z-index: 3;
      bottom: 0;
      left: 6vw;
      max-width: 10vw;
    }
  }
  .hero2 {
    position: relative;
    z-index: 2;
  }
  .toplead {
    position: relative;
    z-index: 2;
  }
}
