.aboutArea {
  background-color: $bg-blue;
  .bgIllust {
    .illust01 {
      right: 0;
      top: 36%;
      max-width: 28vw;
      @include media-breakpoint-up(sm) {
        top: 20%;
      }
    }
    .illust02 {
      left: 0;
      bottom: 30%;
      max-width: 25vw;
      @include media-breakpoint-up(sm) {
        bottom: 10%;
      }
    }
  }
  .contentsContainer {
    position: relative;
    z-index: 2;
  }
}
