.historyArea {
  .bgIllust {
    .illust15 {
      left: 3%;
      top: 1%;
      max-width: 10vw;
      @include media-breakpoint-up(sm) {
        left: 3%;
        top: 4%;
      }
    }
  }
}
