.column {
  border: 1px solid $primary;
  padding: 30px 24px;
  border-radius: $border-radius;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 24px;
  height: 100%;
  @include media-breakpoint-up(sm) {
    padding: 30px;
  }
  .header {
    width: 100%;
    .title {
      margin: 0;
      font-weight: bold;
      text-align: center;
      font-size: 15px;
      &.lg {
        @include media-breakpoint-up(sm) {
          font-size: 18px;
        }
      }
    }
  }
  .body {
    width: 100%;
    // font-size: 13px;
    .wysiwyg{
      p{
        margin-bottom: 10px;
        &:last-child{
          margin-bottom: 0;
        }
      }
    }
    table {
      width: 100%;
      text-align: center;
      border: solid 1px $body-color;
      .colummHeader {
        background-color: #8db1c3;
        th {
          color: $white;
          border-bottom: solid 1px $body-color;
        }
      }
      tr {
        td {
        }
      }
    }
    p {
      margin: 0;
    }
  }
}
