.catchCopy {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 30px;
  text-align: center;
  line-height: 1.5;
  @include media-breakpoint-up(sm) {
    font-size: 24px;
  }
}
