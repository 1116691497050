.midashi1 {
  text-align: center;
  color: $primary;
  margin: 0;
  margin-bottom: 12px;
  font-size: 21px;
  font-weight: bold;
  @include media-breakpoint-up(sm) {
    font-size: 24px;
    margin-bottom: 15px;
  }
}
