.ctaArea {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  .title {
    margin: 0;
    font-size: 18px;
    @include media-breakpoint-up(sm) {
      font-size: 21px;
    }
  }
  .text {
    margin: 0;
    font-size: 14px;
    @include media-breakpoint-up(sm) {
      font-size: 15px;
    }
  }
  .cta {
    padding: 10px 0;
  }
  .comment {
    margin: 0;
    font-size: 12px;
    @include media-breakpoint-up(sm) {
      font-size: 13px;
    }
  }
}
