.movie {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
  .cont {
    flex-basis: 50%;
    @include media-breakpoint-up(sm) {
      flex-basis: 100%;
    }
  }
  .title {
    flex: 1;
    font-size: 13px;
    p {
      margin: 0;
    }
    @include media-breakpoint-up(sm) {
      font-size: 15px;
    }
  }
}
