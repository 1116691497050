$headerHeight: 80px;
$headerHeightSM: 100px;
#header {
  position: sticky;
  width: 100%;
  height: $headerHeight;
  top: 0;
  z-index: $zindex-sticky;
  transition: $transition-base;
  display: none;
  &.sclolled {
    background-color: $bg-beige;
  }
  @include media-breakpoint-up(lg) {
    height: $headerHeightSM;
    padding: 0 2vw;
    display: block;
    .group {
      display: grid;
      grid-template:
        "...  gnav  unav" #{$headerHeightSM}
        / 1fr auto 1fr;
      align-items: center;
      .gnav {
        grid-area: gnav;
        display: flex;
        flex-direction: row;
        gap: 20px;
        list-style: none;
        margin: 0;
        li {
          a {
            text-decoration: none;
            color: $body-color;
            padding-bottom: 8px;
            &:hover{
              color: $primary;
              border-bottom: 2px dashed $primary;
            }
          }
        }
      }
      .unav {
        grid-area: unav;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        .sns {
          display: flex;
          flex-direction: row;
          gap: 25px;
          list-style: none;
          margin: 0;
          li {
            a {
              text-decoration: none;
              color: $body-color;
            }
          }
        }
        .language {
          display: flex;
          flex-direction: row;
          gap: 25px;
          list-style: none;
          margin: 0;
          li {
            a {
              text-decoration: none;
              color: $body-color;
              img {
                padding-right: 10px;
              }
            }
          }
        }
      }
    }
  }
}
