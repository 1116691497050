.digestCover {
  position: relative;
  .bg {
    position: relative;
    width: 100%;
    overflow: hidden;
    z-index: 1;
    &::before {
      content: "";
      width: 100%;
      padding-top: 55%; //高さの比率 ÷ 幅の比率 × 100
      display: block;
      @include media-breakpoint-up(sm) {
        padding-top: 40%;
      }
    }
    img {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .cont {
    padding: 0 25px;
    position: relative;
    z-index: 2;
    margin-top: -45px;
    @include media-breakpoint-up(sm) {
      margin-top: 0;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 0;
      display: flex;
      align-items: center;
    }
    .inner {
      background-color: $white;
      padding: 45px 15px;
      @include media-breakpoint-up(sm) {
        padding: 60px 45px;
        position: absolute;
        left: 50%;
        max-width: 484px;
        &.left {
          left: auto;
          right: 50%;
        }
      }
    }
    .title {
      font-size: 18px;
      font-weight: bold;
      line-height: 1.5;
      margin-bottom: 30px;
      @include media-breakpoint-up(sm) {
        font-size: 24px;
      }
    }
    .text {
      line-height: 2;
      margin-bottom: 30px;
      color: $secondary;
    }
    .moreLink {
      color: $body-color;
    }
  }
}
