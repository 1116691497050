.midashi2 {
  margin: 0;
  padding: 20px 0;
  border-top: solid 1px $primary;
  border-bottom: solid 1px $primary;
  font-size: 14px;
  line-height: 1.5;
  @include media-breakpoint-up(sm) {
    padding: 25px 0;
    font-size: 15px;
  }
}
