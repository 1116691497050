.heroBG {
  position: relative;
  margin-top: calc(#{$headerHeight} / -1);
  @include media-breakpoint-up(sm) {
    margin-top: calc(#{$headerHeightSM} / -1);
  }
  > .bg {
    overflow: hidden;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    .wave {
      width: 100%;
      height: auto;
      position: absolute;
      z-index: 2;
      bottom: calc(100% - 80vh);
      left: 0;
      @include media-breakpoint-up(sm) {
        bottom: calc(100% - 100vh);
      }
      img {
        width: 100%;
      }
    }
    .bgColor1 {
      background-color: $bg-beige;
      width: 100%;
      height: 80vh;
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      @include media-breakpoint-up(sm) {
        height: 100vh;
      }
    }
    .bgColor2 {
      background-color: $bg-blue;
      width: 100%;
      height: calc(100% - 80vh);
      position: absolute;
      z-index: 1;
      top: 80vh;
      left: 0;
      @include media-breakpoint-up(sm) {
        height: calc(100% - 100vh);
        top: 100vh;
      }
    }
    .heroBG00 {
      position: absolute;
      z-index: 3;
      top: 0;
      left: 0;
      width: 100%;
    }
    .heroBG01 {
      position: absolute;
      z-index: 3;
      top: -10px;
      left: -10px;
      max-width: 36vw;
      @include media-breakpoint-up(sm) {
        max-width: 36vw;
        top: -1vw;
        left: -5vw;
      }
      @include media-breakpoint-up(lg) {
        max-width: 30vw;
        top: -6vw;
        left: -8vw;
      }
    }
    .heroBG02 {
      position: absolute;
      z-index: 3;
      top: -10px;
      right: -10px;
      max-width: 60vw;
      @include media-breakpoint-up(sm) {
        max-width: 45vw;
        top: -2vw;
        right: -5vw;
      }
      @include media-breakpoint-up(lg) {
        max-width: 30vw;
        top: -2vw;
        right: -5vw;
      }
    }
    .heroBG03 {
      position: absolute;
      z-index: 3;
      top: 80vh;
      left: -10px;
      height: 30vh;
      img {
        height: 100%;
        width: auto;
      }
      @include media-breakpoint-up(sm) {
        max-width: 60vw;
        left: -5vw;
        height: auto;
        top: auto;
        bottom: calc(100% - 100vh - 5vh);
        img {
          height: auto;
        }
      }
      @include media-breakpoint-up(lg) {
        max-width: 40vw;
        left: -7vw;
        height: auto;
        top: auto;
        bottom: calc(100% - 100vh - 10vh);
        img {
          height: auto;
        }
      }
    }
    .heroBG04 {
      position: absolute;
      z-index: 3;
      top: 55vh;
      right: -20px;
      max-width: 50vw;
      @include media-breakpoint-up(sm) {
        max-width: 30vw;
        top: auto;
        bottom: calc(100% - 100vh - 1vh);
        right: 3vw;
      }
      @include media-breakpoint-up(lg) {
        max-width: 27vw;
        top: auto;
        bottom: calc(100% - 100vh - 12vh);
        right: 6vw;
      }
      p {
        margin: 0;
        font-size: 10px;
        color: $white;
        position: absolute;
        z-index: 3;
        top: 90%;
        right: 25%;
        @include media-breakpoint-up(sm) {
          font-size: 15px;
        }
      }
    }
    .heroBG05 {
      position: absolute;
      z-index: 3;
      bottom: 0;
      left: 6vw;
      max-width: 10vw;
    }
  }
  .hero {
    position: relative;
    z-index: 2;
  }
  .toplead {
    position: relative;
    z-index: 2;
  }
}
