.toplead {
  padding: 60px 0;
  padding-top: 60vw;
  @include media-breakpoint-up(sm) {
    padding: calc(60 / 820 * 100vw) 0 calc(90 / 820 * 100vw);
    padding-top: 20vw; //
  }
  @include media-breakpoint-up(lg) {
    padding: calc(60 / 1366 * 100vw) 0 calc(90 / 1366 * 100vw);
  }
  .catchCopy {
  }
  .leadCopy {
  }
  .more {
    text-align: center;
  }
}
