#footer {
  .sns {
    padding: 0 0 45px;
    position: relative;
    @include media-breakpoint-up(sm) {
      padding: 0 0 60px;
    }
    .illust {
      position: absolute;
      bottom: -20px;
      left: 6vw;
      @include media-breakpoint-up(sm) {
        bottom: -30px;
        left: 12vw;
      }
      img {
        height: 85px;
        width: auto;
        @include media-breakpoint-up(sm) {
          height: 134px;
        }
      }
    }
    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 36px;
      gap: 1em;
      li {
        a {
          color: $black;
        }
      }
    }
  }
  .cont {
    background-color: $bg-beige;
    padding: 45px 6vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    text-align: center;
    .logo {
      margin: 0;
    }
    .tel {
      margin: 0;
      font-size: 14px;
    }
    .text {
      margin: 0;
      font-size: 12px;
    }
    .copyright {
      font-size: 12px;
    }
  }
}
