@import "custom";
@import "~bootstrap/scss/bootstrap";

html {
  font-size: 15px;
  // font-feature-settings: "pwid";
  // letter-spacing: 0.08em;
}

@mixin fontSize($font_size: 10) {
  font-size: calc($font_size / 390 * 100vw);
}
@mixin fontSizeSM($font_size: 10) {
  font-size: calc($font_size / 820 * 100vw);
}
@mixin fontSizeLG($font_size: 10) {
  font-size: calc($font_size / 1366 * 100vw);
}
$bg-beige: #f1ebdf;
$bg-blue: #d5e7ef;
img {
  max-width: 100%;
  height: auto;
}
.ratio {
  img.cover {
    object-fit: cover;
  }
  img.contain {
    object-fit: contain;
  }
}
@import "components/header";
@import "components/toggleMenu";
@import "components/layout";
@import "components/catchCopy";
@import "components/leadCopy";
@import "components/attention";
@import "components/program";
// @import "components/program2";
@import "components/profile";
@import "components/summary";
@import "components/outline";
@import "components/digest";
@import "components/digestCover";
@import "components/movie";
@import "components/infomationTable";
@import "components/column";
@import "components/article";
@import "components/sectionHeader";
@import "components/secondHeader";
@import "components/coverBgImg";
@import "components/sideMenu";
@import "components/tagCloud";
@import "components/narrowThumbnailLink";
@import "components/imgList";
@import "components/carousels";
@import "components/formTable";
@import "components/brandTable";
@import "components/flow";
@import "components/faq";
@import "components/newsList";
@import "components/newsBar";
@import "components/newsArchive";
@import "components/catchCopyBar";
@import "components/catchCopyBorder";
@import "components/anchorLinks";
@import "components/outlineCard";
@import "components/midashi1";
@import "components/midashi2";
@import "components/midashi3";
@import "components/heroBG";
@import "components/hero";
@import "components/heroBG2";
@import "components/hero2";
@import "components/toplead";
@import "components/aboutArea";
@import "components/programArea";
@import "components/profileArea";
@import "components/ctaArea";
@import "components/movieArea";
@import "components/historyArea";
@import "components/archiveItem";
@import "components/historyArchive";
@import "components/footer";
@import "components/vif";
