.profileArea {
  .bgIllust {
    .illust08 {
      display: none;
      @include media-breakpoint-up(sm) {
        left: 3%;
        top: 1%;
        display: block;
      }
    }
    .illust09 {
      right: 3%;
      top: 6%;
      max-width: 11vw;
      @include media-breakpoint-up(sm) {
        right: 3%;
        top: 5%;
      }
    }
    .illust10 {
      left: 3%;
      bottom: 72%;
      max-width: 12vw;
      @include media-breakpoint-up(sm) {
        left: 3%;
        bottom: 66%;
      }
    }
    .illust11 {
      right: 3%;
      bottom: 54%;
      max-width: 11vw;
      @include media-breakpoint-up(sm) {
        right: 3%;
        bottom: 50%;
      }
    }
    .illust12 {
      left: 3%;
      bottom: 28%;
      max-width: 10vw;
      @include media-breakpoint-up(sm) {
        left: 3%;
        bottom: 25%;
      }
    }
    .illust13 {
      right: 3%;
      bottom: 15%;
      max-width: 10vw;
      @include media-breakpoint-up(sm) {
        right: 4%;
        bottom: 9%;
      }
    }
    .illust14 {
      left: 3%;
      bottom: 5%;
      max-width: 10vw;
      @include media-breakpoint-up(sm) {
        left: 3%;
        bottom: 2%;
      }
    }
  }
  .contentsContainer {
    position: relative;
    z-index: 2;
  }
}
