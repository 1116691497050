.secondHeader {
  position: relative;
  .bg {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &::after {
      background-color: black;
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      opacity: 0.5;
    }
  }
  .cont {
    position: relative;
    color: $white;
    padding: 60px 6vw;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 30px;
    @include media-breakpoint-up(sm) {
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      padding: 70px 6vw;
    }
    @include media-breakpoint-up(xl) {
      padding: 90px 6vw;
    }

    > .header {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 15px;
      .title-en {
        font-family: $font-family-en;
        font-weight: bold;
        font-size: 30px;
        margin: 0;
        line-height: 1.2;
        @include media-breakpoint-up(sm) {
          font-size: 36px;
        }
      }
      .title-ja {
        align-items: center;
        justify-content: flex-start;
        gap: 10px;
        display: flex;
        font-size: 12px;
        margin: 0;
        @include media-breakpoint-up(sm) {
          font-size: 13px;
        }
        &::before,
        &::after {
          content: "";
          width: 21px;
          height: 1px;
          background-color: white;
        }
        &::after {
          display: none;
        }
      }
    }
    .footer {
    }
  }
  &.center {
    align-items: center;
    .bg {
      img {
      }
    }
    .cont {
      align-items: center;
      @include media-breakpoint-up(sm) {
        flex-direction: column;
      }
      > .header {
        align-items: center;
        .title-en {
        }
        .title-ja {
          &::after {
            display: block;
          }
        }
      }
      > .footer {
      }
    }
  }
}
