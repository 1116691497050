.anchorLinks {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  gap: 1.75em;
  list-style: none;
  font-size: 15px;
  @include media-breakpoint-up(sm) {
    font-size: 24px;
  }
  li {
    a {
      display: inline-block;
      text-decoration: none;
      color: $body-color;
      border-bottom: 2px dashed transparent;
      &:hover {
        border-bottom: 2px dashed $primary;
        color: $primary;
      }
    }
  }
}
