.movieArea {
  .bgIllust {
    .illust14 {
      right: 3%;
      top: 0;
      max-width: 10vw;
      @include media-breakpoint-up(sm) {
        right: 4%;
        top: 0;
      }
    }
  }
}
