// Custom.scss
// Option B: Include parts of Bootstrap

// Required
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

@import "custom/all";
.btn-more {
  min-width: 200px;
  @include media-breakpoint-up(sm) {
    min-width: 250px;
  }
}
.btn-cta {
  min-width: 360px;
  @include media-breakpoint-up(sm) {
    min-width: 400px;
  }
}
.btn-white {
  color: $primary !important;
  &:hover {
    background-color: $primary !important;
    color: $white !important;
  }
}

// Optional
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
