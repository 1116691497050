.hero {
  position: relative;
  width: 100%;
  height: 80vh;
  margin-top: calc(#{$headerHeight} / -1);
  @include media-breakpoint-up(sm) {
    height: 100vh;
    margin-top: calc(#{$headerHeightSM} / -1);
  }
  .catchCopy {
    margin: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 10%;
    img {
      width: 85vw;
    }
    @include media-breakpoint-up(sm) {
      img {
        width: 70vw;
      }
    }
    @include media-breakpoint-up(lg) {
      img {
        width: 54vw;
        max-width: 800px;
      }
    }
  }
  .info {
    margin: 0;
    position: absolute;
    bottom: 18vh;
    left: 5vw;
    width: 45vw;
    img {
      width: 100%;
    }
    @include media-breakpoint-up(sm) {
      width: 30vw;
      left: calc(50% - 15vw);
      bottom: 25%;
    }
    @include media-breakpoint-up(lg) {
      bottom: 18%;
      width: 16vw;
      left: calc(50% - 8vw);
    }
  }
}
