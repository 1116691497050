.profile {
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin-bottom: 40px;
  @include media-breakpoint-up(sm) {
    gap: 28px;
    margin-bottom: 80px;
  }
  .header {
    background-color: tint-color($primary, 70%);
    border-radius: 10px;
    padding: 20px 0;
    text-align: center;
    .title {
      margin: 0;
      font-size: 20px;
      @include media-breakpoint-up(sm) {
        font-size: 24px;
      }
    }
  }
  .body {
    .item {
      display: flex;
      flex-direction: column;
      gap: 25px;
      padding-bottom: 35px;
      @include media-breakpoint-up(sm) {
        gap: 30px;
        padding-bottom: 50px;
      }
      .midashi2 {
      }
      @include media-breakpoint-up(sm) {
        &:nth-child(even) {
          .cont {
            flex-direction: row-reverse;
          }
        }
      }
      .cont {
        display: flex;
        flex-direction: column;
        gap: 25px;
        @include media-breakpoint-up(sm) {
          flex-direction: row;
          gap: 20px;
          align-items: center;
          &.reverse {
            flex-direction: row-reverse;
          }
        }
        .img {
          display: flex;
          justify-content: center;
          width: 100%;
          height: 100%;
          @include media-breakpoint-up(sm) {
            flex-basis: 40%;
          }
          img {
            border-radius: 50%;
            width: 295px;
            height: 295px;
            object-fit: cover;
          }
        }
        .nameBox {
          display: flex;
          flex-direction: column;
          gap: 18px;
          @include media-breakpoint-up(sm) {
            flex: 1;
            gap: 26px;
          }
          .name {
            margin: 0;
            font-size: 18px;
            @include media-breakpoint-up(sm) {
              font-size: 21px;
            }
          }
          .text {
            margin: 0;
            font-size: 13px;
            line-height: 2;
            @include media-breakpoint-up(sm) {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
