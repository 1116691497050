.infomationTable {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  dl {
    margin: 0;
    padding: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: $white;
    border-radius: $border-radius;
    @include media-breakpoint-up(sm) {
      flex-direction: row;
      font-size: 18px;
      padding: 10px;
      align-items: stretch;
    }
    dt {
      margin: 0;
      padding: 20px 20px 10px;
      position: relative;
      width: 100%;
      font-weight: normal;
      @include media-breakpoint-up(sm) {
        width: 8em;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding: 15px 20px;
      }
      &::after {
        position: absolute;
        bottom: 0;
        left: 20px;
        width: 20px;
        height: 1px;
        background-color: $primary;
        content: "";
        @include media-breakpoint-up(sm) {
          bottom: 0;
          left: auto;
          right: 0;
          width: 1px;
          height: 100%;
        }
      }
    }
    dd {
      margin: 0;
      padding: 10px 20px 20px;
      width: 100%;
      font-weight: normal;
      a {
        color: $primary;
      }
      p{
        margin: 0;
      }
      @include media-breakpoint-up(sm) {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding: 15px 30px;
      }
    }
  }
}
