.attention {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 22px;
  // border: 1px dashed $primary;
  background-color: $white;
  border-radius: 10px;
  margin-bottom: 10px;
  @include media-breakpoint-up(sm) {
    flex-direction: row;
    align-items: center;
    gap: 5vw;
    padding: 30px 50px;
  }
  .header {
    flex-basis: 30%;
    text-align: center;
    font-size: 15px;
    @include media-breakpoint-up(sm) {
      font-size: 18px;
    }
  }
  .body {
    flex: 1;
    font-size: 13px;
  }
}
