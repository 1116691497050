.program2 {
  display: flex;
  flex-direction: column;
  gap: 13px;
  margin-bottom: 30px;
  @include media-breakpoint-up(sm) {
    gap: 40px;
    margin-bottom: 60px;
  }
  .header {
    background-color: $white;
    padding: 20px 0;
    text-align: center;
    .title {
      margin: 0;
      font-size: 20px;
      @include media-breakpoint-up(sm) {
        font-size: 24px;
      }
    }
  }
  .body {
    .item {
      display: flex;
      flex-direction: column;
      padding-bottom: 10px;
      @include media-breakpoint-up(sm) {
        display: grid;
        grid-template:
          "time ...... program-title" auto
          "... ...... ..." 20px
          "... ...... cont" auto
          / 25% 25px 1fr;
        padding-bottom: 40px;
      }
      .time {
        background-color: tint-color($primary, 25%);
        color: $white;
        padding: 13px 0;
        text-align: center;
        @include media-breakpoint-up(sm) {
          padding: 13px;
          height: 100%;
          grid-area: time;
        }
        .time01 {
          font-size: 15px;
          padding-bottom: 5px;
        }
        .time02 {
          font-size: 12px;
        }
      }
      .program-title {
        background-color: $white;
        font-size: 15px;
        margin: 0;
        line-height: 1.5;
        padding: 20px 20px 0 20px;
        @include media-breakpoint-up(sm) {
          background-color: transparent;
          font-size: 22px;
          color: $primary;
          grid-area: program-title;
          height: 100%;
          display: flex;
          align-items: center;
          padding: 0;
        }
      }
      .cont {
        background-color: $white;
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        @include media-breakpoint-up(sm) {
          background-color: transparent;
          grid-area: cont;
          padding: 0;
        }

        .data {
          display: flex;
          flex-direction: row;
          gap: 10px;
          align-items: flex-start;
          @include media-breakpoint-up(sm) {
            gap: 25px;
          }
          .label {
            flex-basis: 18%;
            color: tint-color($primary, 25%);
            border: solid 1px tint-color($primary, 25%);
            border-radius: 10px;
            padding: 5px 10px;
            text-align: center;
            font-size: 13px;
            white-space: nowrap;
            @include media-breakpoint-up(sm) {
              flex-basis: 10%;
              font-size: 15px;
            }
          }
          .text {
            flex: 1;
            margin: 0;
            font-size: 16px;
            padding-top: 5px;
            @include media-breakpoint-up(sm) {
              padding-top: 2px;
              font-size: 20px;
              br {
                display: none;
              }
            }
          }
        }
        div {
          p {
            margin: 0;
            font-size: 13px;
          }
        }
      }
    }
  }
}
