.hero2 {
  position: relative;
  width: 100%; //
  height: 162vw;
  // margin-top: calc(#{$headerHeight} / -1);
  @include media-breakpoint-up(sm) {
    height: 144vw;
  }
  @include media-breakpoint-up(lg) {
    height: 100vh;
    // margin-top: calc(#{$headerHeightSM} / -1);
  }
  .catchCopy {
    margin: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    // padding-bottom: 5%;//
    img {
      // width: 85vw;
    }
    @include media-breakpoint-up(lg) {
      img {
        // width: 65vw;
        // max-width: 850px; //
      }
    }
    @include media-breakpoint-up(xl) {
      img {
        // width: 54vw;
      }
    }
  }
  .info {
    margin: 0;
    position: absolute;
    bottom: 25vw;
    left: 5vw;
    width: 45vw;
    img {
      width: 100%;
    }
    @include media-breakpoint-up(sm) {
      width: 36vw;
      bottom: 36vw;
    }
    @include media-breakpoint-up(lg) {
      bottom: 10vw;
      width: 16vw;
      left: calc(50% - 8vw);
    }
    @include media-breakpoint-up(xl) {
      bottom: 6vw;
    }
  }
}
