.programArea {
  background-color: #f8f3e9;
  .bgIllust {
    .illust03 {
      left: 6%;
      top: 0;
      max-width: 10vw;
      @include media-breakpoint-up(sm) {
      }
    }
    .illust04 {
      display: none;
      @include media-breakpoint-up(sm) {
        right: 4%;
        bottom: 67%;
        display: block;
      }
    }
    .illust05 {
      right: 4%;
      bottom: 6%;
      max-width: 15vw;
      @include media-breakpoint-up(sm) {
        right: 4%;
        bottom: 37%;
      }
    }
    .illust06 {
      left: 4%;
      bottom: -2%;
      max-width: 15vw;
      @include media-breakpoint-up(sm) {
        left: 4%;
        bottom: 25%;
      }
    }
    .illust07 {
      display: none;
      @include media-breakpoint-up(sm) {
        left: 0;
        bottom: -2%;
        display: block;
      }
    }
  }
  .contentsContainer {
    position: relative;
    z-index: 2;
  }
}
